import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, Extern, FirstP, Emoji } from "./../../../components/helpers.js"

export default ({ data, pageContext }) => {

    const content = <>

        <FirstP>In this project I will design my own clock. The idea for this clock is not to accurately track time to the second, but rather to show the progression of time throughout the day. Furthermore, the clock should be able to display additional information, such as your schedule for the day.</FirstP>
         
        <p>Before the start of the project I made two design decisions. First, the clock should be a linear clock. This design is not very common for a clock, but 
            especially for the idea that I have in mind, makes a lot of sense. Computers introduced the progress bar to us, so by now reading one should be 
            very intuitive. The second design choice is to use addressable RGB leds. This is something new for me, and using them will open up many new project 
            possibilities.
        </p>

        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="Linear clock" />
        <Caption>First design mock-up. The top view shows the clock and the bottom view is an example for how a calendar could be displayed.</Caption>

        <p>In the picture above a sketch of the design is shown. In this post I will focus on the design itself. 
        There are three parts to the design: the housing/frame, front panel, and light guides. The housing will be 3D printed whereas the other parts will be laser cut.
        Since I have a (new <Emoji e="😎" />) 3D printer at home I designed the laser cut parts first, because they have the longest lead time.
        The 3D printed housing as well as the electronics will be discussed in a later post.</p>

        <h3>Light guides</h3>

        <p>I want the final product to look as close as possible to the mock-up shown above. The squares of light should have sharp edges and be nicely diffused.
            Furthermore, I also want the numbers and tick marks to have back-lighting. For this I chose to experiment with laser engraved acrylic, such as used 
            in the <Extern href="https://hackaday.com/2017/01/14/smoothly-modernized-nixie-display/">Lixie display</Extern> and various 
            other <Extern href="https://www.youtube.com/results?search_query=edge+lit+acrylic+sign">edge lit signs</Extern>.
        </p>

        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="Light guides" />
        <Caption>My laser cutting order for the light guides. Each block and each tick marker is a separate piece which will later be held together in a frame.</Caption>

        <p>
            The working principle behind this is quite nice. To put it simply: light that has entered inside the acrylic will reflect internally and stay trapped 
            inside of the light guide, unless the angle between the light beam and the surface becomes too large. 
            Then, when you engrave something on the surface, the surface becomes rough so that the light hitting the surface there will scatter in all directions.
            This will make the engraved shape light up.
        </p>

        <p>
            In this case I will have a LED strip at the top and bottom of these pieces so that the light will be reflected to show the squares and tick marks on their face.
            The engraving will be placed on the back side (hence the mirror view of the numbers) so that the front face will look more clean when not lighted.
            I opted for medium density engraving since that looked the best in the examples, but also included a few pieces at different levels so that I know for the future
            how they compare.
        </p>

        <h3>Front Panel</h3>

        <p>
            As I mentioned before the housing will be 3D printed, but this is not the case for the front panel. I am not yet sure what the best look will be, therefore I have ordered
            three different versions. From top to bottom:
        </p>

        <ol>
            <li>The first design is done for a two color material. The front panel will be black with cutouts for the led blocks, but not for the numbers. these
                will be engraved in the front plate resulting in white markings. Of course this means that the numbers and ticks can not be lighted, but I 
                wanted to see this in person to judge the look.
            </li>
            <li>
                The second design is ordered in a transparent dark grey acrylic. This will hopefully give a somewhat darker front while still transmitting all the light 
                coming from the leds behind. To give the front some more relief, I have still cutout the led bars.
            </li>
            <li>
                The final design is from the same material, but now without any cutouts. This will be the most clean look. I am curious how they will compare in practice.
            </li>
        </ol>

        <Img fadeIn={false} fluid={data.img3.childImageSharp.fluid} alt="Front panel" />
        <Caption>My laser cutting order for three different variants of the front panel.</Caption>

        <p>
            As you can see, each panel also has subtle extensions on the left and right side. I hope to use those to click the front panel into the housing. 
            I have used a similar method in the past successfully. This prevents the need for glue so that I can always disassemble the panel later. You can
            find the source files discussed in this post on <Extern href="https://github.com/maakbaas/linear-calendar-clock">GitHub</Extern>.
        </p>

    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "clock.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "keys.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img3: file(relativePath: { eq: "frontpanel.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`